import React from "react";
import './planblock.scss';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
const PlanBlock = (props) => {
    return(
        props.data ?
        <ModuleSection
            sectionClass={`planblock border-top`}
            // removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : false}
            // sectionSpacing={moduleData?.sectionSpacing}
        >
            <ModuleTitle
                title="Payment Plan"
                className="module__titleTwo mb-16 mb-md-40"
            />
            <div className="plan-grid row row-cols-2 row-cols-lg-4 g-12 gx-md-16 gy-md-40 g-lg-40">
                {props.data?.payment1 && props.data?.payment1_type &&
                    <Col>
                        <div className="plan-grid-list">
                            <div className="plan-no">{props.data?.payment1} %</div>
                            <div className="plan-text">{props.data?.payment1_type}</div>
                        </div>
                    </Col>
                }
                {props.data?.payment2 && props.data?.payment2_type &&
                    <Col>
                        <div className="plan-grid-list">
                            <div className="plan-no">{props.data?.payment2} %</div>
                            <div className="plan-text">{props.data?.payment2_type}</div>
                        </div>
                    </Col>
                }
                {props.data?.payment3 && props.data?.payment3_type &&
                    <Col>
                        <div className="plan-grid-list">
                            <div className="plan-no">{props.data?.payment3} %</div>
                            <div className="plan-text">{props.data?.payment3_type}</div>
                        </div>
                    </Col>
                }
                {props.data?.payment4 && props.data?.payment4_type &&
                    <Col>
                        <div className="plan-grid-list">
                            <div className="plan-no">{props.data?.payment4} %</div>
                            <div className="plan-text">{props.data?.payment4_type}</div>
                        </div>
                    </Col>
                }
                {props.data?.payment5 && props.data?.payment5_type &&
                    <Col>
                        <div className="plan-grid-list">
                            <div className="plan-no">{props.data?.payment5} %</div>
                            <div className="plan-text">{props.data?.payment5_type}</div>
                        </div>
                    </Col>
                }
                {props.data?.payment6 && props.data?.payment6_type &&
                    <Col>
                        <div className="plan-grid-list">
                            <div className="plan-no">{props.data?.payment6} %</div>
                            <div className="plan-text">{props.data?.payment6_type}</div>
                        </div>
                    </Col>
                }
            </div>
        </ModuleSection>
    : null)
}

export default PlanBlock
